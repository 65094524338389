<template>
    <div class="amount">
        <template v-if="amount.discount">
            <div class="price">
                <div class="old">
                    <del>{{ $n(amount.price * quantity, 'currency') }}</del>
                </div>
                <div class="new">{{ $n(amount.discount.price * quantity, 'currency') }}</div>
            </div>
            <div class="discount">
                %{{ amount.discount.percent }}
            </div>
        </template>
        <template v-if="!amount.discount">
            <div class="price">
                <div class="new">{{ $n(amount.price * quantity, 'currency') }}</div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'Amount',
    props: {
        amount: {
           //
        },
        quantity: {
            default: 1
        }
    },
};
</script>
