let names = (window.location.hostname).split('.');
let tenant = names[names.length - 2];
let domain = window.location.protocol + '//' + tenant + '.' + names[names.length - 1];

export const config = {
    tenantName: tenant,
    endPoint: names[names.length - 1] === 'test' ? 'https://minidil.test' : 'https://minidil.tech',
    apiEndPoint: names[names.length - 1] === 'test' ? 'https://tisuite.test/api/v1' : 'https://tisuite.co/api/v1',

    domain: domain,
}

export default config;
