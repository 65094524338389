import Vue from 'vue'

// misc
Vue.component('script-misc-try', () => import('./src/Misc/Try'));
Vue.component('script-misc-try-form', () => import('./src/Misc/TryForm'));
Vue.component('script-misc-contract', () => import('./src/Misc/Contract'));
Vue.component('script-misc-we-call-you', () => import('./src/Misc/WeCallYou'));
Vue.component('script-misc-account', () => import('./src/Misc/Account'));
Vue.component('script-misc-checkout', () => import('./src/Misc/Checkout'));
Vue.component('script-misc-contact', () => import('./src/Misc/Contact'));
Vue.component('script-misc-feedback', () => import('./src/Misc/Feedback'));
Vue.component('script-misc-job', () => import('./src/Misc/Job'));
Vue.component('script-misc-video-button', () => import('./src/Misc/VideoButton'));

// auth
Vue.component('script-auth-dialog', () => import('./src/Auth/Dialog'));

// catalog
Vue.component('script-catalog-webinar', () => import('./src/Catalog/Webinar'));
Vue.component('script-catalog-product', () => import('./src/Catalog/Product'));
Vue.component('script-catalog-product-with-basket', () => import('./src/Catalog/ProductWithBasket'));

// checkout
Vue.component('script-checkout', () => import('./src/Checkout/Index'));
